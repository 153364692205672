import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Some speech shuts down more expression than it opens up",
  "date": "2015-08-02T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Medium recently updated its policy to prohibit public shaming amongst other things (emphasis mine):`}</p>
    <blockquote>
      <p parentName="blockquote">{`We want everyone to feel at home on Medium. This doesn’t mean you’re free from having your views challenged or your ideas re-characterized. Surprising or controversial views prompt exactly the kind of impassioned engagement we want here. `}<strong parentName="p">{`But some speech shuts down more expression than it opens up, by causing silence, retreat, isolation, or intimidation`}</strong>{`. No one should feel any less than who they are from spending time here. And that’s where we decided to draw the line.`}</p>
    </blockquote>
    <p>{`Bingo.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      